import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
//import axios from "axios";
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)


export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin
    },
    data: function() {
        return {
            pageTitle: "List of employees",
            moreParams: {},
            base_url: Vue.BASE_URL,
            fields: [{
                    name: VuetableFieldCheckbox,
                    titleClass: 'text-center',
                    dataClass: 'text-center'
                },
                {
                    name: 'job_id',
                    title: 'Job ID',
                    sortField: 'job_id',
                    dataClass: 'text-center'
                },
                {
                    name: 'Ambatovy_ID',
                    title: 'Ambatovy ID',
                    dataClass: 'text-center',
                    sortField: 'Ambatovy_ID'
                },
                {
                    name: 'emp_ename',
                    title: 'Name',
                    sortField: 'emp_ename'
                },
                {
                    name: 'last_ename',
                    title: 'Last Name',
                    sortField: 'last_ename'
                },
                {
                    name: 'Position',
                    title: 'Position',
                    sortField: 'Position'
                },
                {
                    name: 'Remarks',
                    title: 'Remarks',
                    sortField: 'Remarks'
                },
                {
                    name: 'Department',
                    title: 'Department',
                    sortField: 'Department'
                },
                {
                    name: 'mada_address',
                    title: 'Address',
                    sortField: 'mada_address'
                },
                {
                    name: 'actions',
                    width: "50px",
                    dataClass: "text-center"
                }

            ],
            sortOrder: [
                { field: 'job_id', direction: 'asc' }
            ],
            css: {
                table: {
                    tableClass: 'table table-striped table-bordered table-hovered',
                    loadingClass: 'loading',
                    ascendingIcon: 'glyphicon glyphicon-chevron-up',
                    descendingIcon: 'glyphicon glyphicon-chevron-down',
                    handleIcon: 'glyphicon glyphicon-menu-hamburger',
                },
                pagination: {
                    infoClass: 'pull-left ',
                    wrapperClass: 'vuetable-pagination text-center',
                    activeClass: 'btn-secondary',
                    disabledClass: 'disabled',
                    pageClass: 'btn btn-border',
                    linkClass: 'btn btn-border',
                    icons: {
                        first: '',
                        prev: '',
                        next: '',
                        last: '',
                    },
                }
            },
            motCle: "",
            tabledata: [],
            json_fields: {
                'Job ID': 'job_id',
                'Ambatovy ID': 'Ambatovy_ID',
                'Name': 'emp_ename',
                'Last Name': 'last_ename',
                'Position': 'Position',
                'Remarks': 'Remarks',
                'Department': 'Department',
            },
            json_data: [],
            json_meta: [
                [{
                    'key': 'charset',
                    'value': 'utf-8'
                }]
            ],

        }
    },
    methods: {
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        editRow(rowData) {
            alert("You clicked edit on" + JSON.stringify(rowData))
        },
        deleteRow(rowData) {
            alert("You clicked delete on" + JSON.stringify(rowData))
        },
        onLoading() {
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        setFilter() {
            this.moreParams.filter = this.motCle;
            this.moreParams.criteriacol = this.criteriacol;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.moreParams.filter = this.motCle;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        onExport() {

            this.tabledata = this.$refs.vuetable.$data.tableData;
            this.json_data = this.$refs.vuetable.$data.tableData;
            $("#excel-download").trigger("click");
        }
    },
    computed: {
        /*httpOptions(){
            return {headers: {'Authorization': "my-token"}} //table props -> :http-options="httpOptions"
        },*/
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);
        
        this.motCle = "";
        this.criteriacol = "";
    },
    mounted: function() {
        // var that = this;

        /*axios.get("https://vuetable.ratiw.net/api/users").then(response => {
            this.fields = response.data.data;
        });*/

        // On change zone de texte
        /*that.moreParams.filter = 'Mot a rechercher';
            Vue.nextTick(() => that.$refs.vuetable.refresh());*/
    }
}